// extracted by mini-css-extract-plugin
export var ArtistDescription = "ERO-module--ArtistDescription--pAkJh";
export var ArtistInfos = "ERO-module--ArtistInfos--RDvpo";
export var ButtonWrapper = "ERO-module--ButtonWrapper --SeHJl";
export var CardWrapper = "ERO-module--CardWrapper--dofhy";
export var CarrouselWrapper2 = "ERO-module--CarrouselWrapper2--iSDt7";
export var Citations = "ERO-module--Citations--AopUy";
export var DescriptionWrapper = "ERO-module--DescriptionWrapper--J4jOL";
export var ImageWrapper = "ERO-module--ImageWrapper--Hidk9";
export var LinkWrapper = "ERO-module--LinkWrapper--YEiQa";
export var MobileProtrait = "ERO-module--MobileProtrait--toaVK";
export var More = "ERO-module--More--1G2ve";
export var MoreButton = "ERO-module--MoreButton--BVopC";
export var NameWrapper = "ERO-module--NameWrapper--gnmtu";
export var PdpWrapper = "ERO-module--PdpWrapper--32sOg";
export var PhotosWrapper = "ERO-module--PhotosWrapper--SKGoA";
export var ProfilWrapper = "ERO-module--ProfilWrapper--8cS52";
export var TitleWrapper = "ERO-module--TitleWrapper--mfSRZ";
export var Wrapper = "ERO-module--Wrapper--2OA4T";